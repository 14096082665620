import React from 'react'

type YoutubeVideoProps = {
  src: string
  className?: string
  width: string
  height: string
  title: string
}

const YoutubeVideo = ({ src, className, width, height, title }: YoutubeVideoProps) => (
  <iframe
    title={title}
    src={src.replace('youtube.com', 'youtube-nocookie.com').replace('watch?v=', 'embed/')}
    className={`optanon-category-C0003 ${className}`}
    frameBorder="0"
    allow="autoplay"
    encrypted-media
    width={width}
    height={height}
    allowFullScreen
  />
)

export default YoutubeVideo
